import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PortableText from 'react-portable-text';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import Downloads from '../components/Downloads';

const TechnologyStyled = styled.div`
  max-width: calc(var(--wrapper) * 0.5);
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  h1 {
    font-size: 3rem;
    margin: 1rem 0 2.5rem;
  }
  h2 {
    font-size: 2.25rem;
    margin: 1rem 0 2rem;
  }
  h3 {
    font-size: 1.5rem;
    margin: 1rem 0 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
    margin: 1rem 0 1.25rem;
  }
  h6 {
    border-top: 1px solid var(--border);
    text-transform: normal;
    padding-top: 2rem;
  }
  .bc-size-chart,
  .table-wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-wrapper > table,
  .table-wrapper > table th,
  .table-wrapper > table td {
    padding: 5px;
    font-size: 13px;
    border: 1px solid #eee !important;
    border-collapse: collapse;
    table-layout: auto;
  }
  .table-wrapper > table {
    margin-bottom: 5px;
  }

  .table-wrapper > table th {
    background: var(--greyLight);
    text-align: right;
    position: sticky;
    left: 0;
  }

  .table-wrapper > table td {
    text-align: center;
  }
`;

const serializers = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === 'blockquote' || style === 'quote') {
        return <blockquote>{props.children}</blockquote>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    code: (props) =>
      console.log('code block', props) || (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
  },
  list: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    )),
  listItem: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    )),
  marks: {
    strong: (props) =>
      console.log('strong', props) || <strong>{props.children}</strong>,
    em: (props) => console.log('em', props) || <em>{props.children}</em>,
    code: (props) =>
      console.log('code', props) || <code>{props.children}</code>,
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

export default function SingleTechnologyPage({ data, pageContext }) {
  const { technology } = data;
  const downloads = technology.technologyPages.filter(
    (item) => item.title === pageContext.title && item.downloads.length > 0
  );
  return (
    <>
      <SEO title={pageContext.title} />
      <TechnologyStyled>
        <div className="wrapper">
          <h1 className="page-title">{pageContext.title}</h1>
          <BlockContent
            blocks={pageContext.content}
            projectId="ji3xh6f0"
            dataset="production"
            serializers={serializers}
            imageOptions={{
              fit: 'max',
            }}
          />
        </div>
        {downloads.length > 0 && <Downloads files={downloads[0].downloads} />}
      </TechnologyStyled>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    technology: sanityTechnology(
      technologyPages: { elemMatch: { slug: { current: { eq: $slug } } } }
    ) {
      technologyPages {
        title
        slug {
          current
        }
        _rawContent
        downloads {
          download {
            asset {
              url
              mimeType
            }
          }
          fileName
        }
      }
    }
  }
`;
